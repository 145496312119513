<template>
  <div>
    <register-users ref="RegisterUsers" />
    <show-users ref="ShowUsers" />
  </div>
</template>
<script>
  export default {
    components: {
      RegisterUsers: () => import('./components/RegisterUsers'),
      ShowUsers: () => import('./components/ShowUsers'),
    },
  }
</script>
